import React from 'react';
import { CgWebsite } from "react-icons/cg";
import { MdPreview } from "react-icons/md";
import { PersonIcon } from 'components/Icons/Icons';
import SignUp from 'views/Pages/SignUp.js';
import SignIn from 'views/Pages/SignIn';
import Profile from 'views/Dashboard/Profile';
import { Roles } from 'constants/common';
import Category from 'views/Dashboard/Category/Category';
import Site from 'views/Dashboard/Site/Site';

var dashRoutes = [
  // {
  //   path: '/dashboard',
  //   name: 'Dashboard',
  //   icon: <HomeIcon color="inherit" />,
  //   layout: '/admin',
  //   component: Dashboard,
  //   role: [Roles.ADMIN],
  // },
  {
    path: '/category',
    name: 'Quản lý danh mục',
    icon: <MdPreview size={20} />,
    layout: '/admin',
    component: Category,
    role: [Roles.ADMIN],
  },
  {
    path: '/site',
    name: 'Quản lý site',
    icon: <CgWebsite size={20} />,
    layout: '/admin',
    component: Site,
    role: [Roles.ADMIN],
  },
  // {
  //   path: '/reviewer/history-match-review/:id',
  //   redirect: true,
  //   name: 'Quản lý reviewer',
  //   icon: <BiFoodMenu size={20} />,
  //   layout: '/admin',
  //   component: HistoryMatchReview,
  //   role: [Roles.ADMIN],
  // },
  {
    name: 'ACCOUNT PAGES',
    category: 'account',
    rtlName: 'صفحات',
    state: 'pageCollapse',
    views: [
      {
        path: '/profile',
        name: 'Profile',
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        layout: '/admin',
        component: Profile,
        role: [Roles.ADMIN, Roles.USER, Roles.GUEST],
      },
      {
        path: '/sign-in',
        layout: '/auth',
        redirect: true,
        component: SignIn,
      },
      {
        path: '/sign-up',
        layout: '/auth',
        redirect: true,
        component: SignUp,
      },
    ],
  },

];

export default dashRoutes;