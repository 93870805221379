import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Text,
  FormControl,
  FormLabel,
  Box,
  Grid,
  GridItem,
  Image,
  IconButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import InputController from 'components/Form/InputController';
import { ModalType, ROOT_API, countries, countriesSlug } from 'constants/common';
import { toast } from 'components/Toast';
import { SiteFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { BsUpload, BsXCircle } from 'react-icons/bs';
import {
  updateThumnail,
  useCreateCategoryMutation,
  useCreateSiteMutation,
  useQueryGetListCategory,
  useUpdateCategoryMutation,
  useUpdateSiteMutation,
} from 'services/category';
import { useDropzone } from 'react-dropzone';
import { nanoid } from 'nanoid';
import { isEmpty } from 'lodash';
import SelectController from 'components/Form/SelectController';
import { createArray, mappingOptionSelect } from 'utils/mapping';
import { CookieStorage } from 'utils/cookie-storage';

const UpdateSiteModal = ({ categories, isOpen, categorysDetail, onClose, refetch }) => {
  const [error, setError] = useState({
    thumbnailFile: null,
    icon: null,
  });
  const [files, setFiles] = useState([]);
  const [icons, setIcons] = useState([]);
  const [isDragEnter, setIsDragEnter] = useState(false);
  const cancelRef = React.useRef();
  const createSiteMutation = useCreateSiteMutation();
  const updateSiteMutation = useUpdateSiteMutation();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(SiteFormValidate),
    defaultValues: {},
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDragEnter: () => {
      setIsDragEnter(true);
    },
    onDragLeave: () => {
      setIsDragEnter(false);
    },
    onDrop: acceptedFiles => {
      if (acceptedFiles.length > 1) {
        setError({ ...error, thumbnailFile: 'Chỉ upload tối đa 1 hình.' });
        return;
      }
      setError({ ...error, thumbnailFile: null });
      setIsDragEnter(false);
      setFiles([
        ...acceptedFiles.map(file => ({
          id: nanoid(),
          preview: URL.createObjectURL(file),
          file,
        })),
      ]);
    },
  });

  const { getRootProps: getRootPropsIcon, getInputProps: getInputPropsIcon } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDragEnter: () => {
      setIsDragEnter(true);
    },
    onDragLeave: () => {
      setIsDragEnter(false);
    },
    onDrop: acceptedFiles => {
      if (acceptedFiles.length > 1) {
        setError({ ...error, icon: 'Chỉ upload tối đa 1 icon.' });
        return;
      }
      setError({ ...error, icon: null });
      setIsDragEnter(false);
      setIcons([
        ...acceptedFiles.map(file => ({
          id: nanoid(),
          preview: URL.createObjectURL(file),
          file,
        })),
      ]);
    },
  });

  const onUploadPhoto = async files => {
    const formData = new FormData();
    const filesUpload = files.filter(item => item.file);
    const filesExist = files.filter(item => !item.file);
    let pathFiles = '';

    if (!isEmpty(filesUpload)) {
      filesUpload.map(item => {
        formData.append('thumbnailFile', item.file);
      });
      const response = await updateThumnail(formData);
      pathFiles = response?.data;
    }
    return [...filesExist, pathFiles];
  };

  useEffect(() => {
    const checkCategoryDetail = (id) => {
      return categories?.find(category => category._id === id)
    }
    if (categorysDetail) {
      reset({
        title: categorysDetail.title,
        description: categorysDetail.description,
        type: checkCategoryDetail(categorysDetail.categoryId) ? {
          label:  checkCategoryDetail(categorysDetail.categoryId)?.title,
          value:  checkCategoryDetail(categorysDetail.categoryId)?._id
        } : null,
        country: {
          label: categorysDetail.country_name,
          value: categorysDetail.country_slug,
        },
        view: categorysDetail.view,
        url: categorysDetail.url,
        thumbnail: categorysDetail.image,
        id: categorysDetail._id,
      });
      categorysDetail?.image &&
        setFiles([
          {
            id: nanoid(),
            preview: ROOT_API + '/' + categorysDetail.image,
            path: categorysDetail.image,
          },
        ]);
      categorysDetail?.icon &&
        setIcons([
          {
            id: nanoid(),
            preview: ROOT_API + '/' + categorysDetail.icon,
            path: categorysDetail.icon,
          },
        ]);
    }
  }, [categorysDetail]);

  const onSubmit = async dataForm => {
    if (isEmpty(icons)) {
      setError({ ...error, icon: 'Tải lên 1 icon' });
      return;
    }
    if (isEmpty(files)) {
      setError({ ...error, thumbnailFile: 'Tải lên 1 hình ảnh' });
      return;
    }
    const photosPath = await onUploadPhoto(files);
    const iconsPath = await onUploadPhoto(icons);
    const dataSubmit = {
      categoryId: dataForm.type.value,
      country_name: dataForm.country.label,
      country_slug: dataForm.country.value,
      image: photosPath[0]?.path ?? photosPath[0],
      icon: iconsPath[0]?.path ?? iconsPath[0],
      url: dataForm.url,
      title: dataForm.title,
      view: dataForm.view,
      description: dataForm.description,
    };

    if (!!categorysDetail) {
      updateSiteMutation.mutate(
        { ...dataSubmit, id: categorysDetail._id },
        {
          onSuccess: () => {
            refetch();
            toast.showMessageSuccess('Cập nhập site thành công');
            onClose(ModalType.ChangeStatus);
          },
          onError: () => {
            toast.showMessageError('Cập nhập site thất bại');
          },
        }
      );

      return;
    }

    createSiteMutation.mutate(dataSubmit, {
      onSuccess: () => {
        refetch();
        toast.showMessageSuccess('Tạo site thành công');
        onClose(ModalType.ChangeStatus);
      },
      onError: () => {
        toast.showMessageError('Tạo site thất bại');
      },
    });
  };

  const onRemove = index => {
    setFiles(prev => prev.filter((i, idX) => idX !== index));
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.ChangeStatus);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">{categorysDetail ? 'Cập nhật' : 'Tạo'} site</AlertDialogHeader>
          <AlertDialogCloseButton />
          <form>
            <AlertDialogBody>
              <InputController
                isRequired
                styleContainer={{
                  marginBottom: '10px',
                }}
                control={control}
                name="title"
                label="Tên"
              />
              <SelectController
                isRequired
                styleContainer={{
                  marginBottom: '10px',
                }}
                control={control}
                name="type"
                label="Danh mục"
                isClearable
                options={mappingOptionSelect(categories, 'title', '_id')}
              />
              <SelectController
                isRequired
                styleContainer={{
                  marginBottom: '10px',
                }}
                control={control}
                name="country"
                label="Quốc gia"
                isClearable
                options={createArray(countries, countriesSlug)}
              />
              <InputController
                isRequired
                styleContainer={{
                  marginBottom: '10px',
                }}
                control={control}
                name="url"
                label="URL"
              />
              <InputController
                styleContainer={{
                  marginBottom: '10px',
                }}
                control={control}
                name="description"
                label="Mô tả"
                type="textarea"
              />
              <InputController
                styleContainer={{
                  marginBottom: '10px',
                }}
                type="number"
                control={control}
                name="view"
                label="Lượt xem"
              />
              <FormControl isRequired pt={4}>
                <FormLabel minW="150px">Ảnh</FormLabel>
                <Box
                  {...getRootProps()}
                  cursor="pointer"
                  borderWidth="1px"
                  borderStyle="dashed"
                  borderRadius="12px"
                  padding="55px"
                  textAlign="center"
                  height="160px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <input {...getInputProps()} />
                  <BsUpload size={40} />
                  {isDragEnter ? (
                    <p>Thả ảnh của bạn</p>
                  ) : (
                    <>
                      <p>
                        <Text as="span" color="red.400">
                          Tải tập tin lên
                        </Text>
                        &nbsp;hoặc kéo và thả
                      </p>
                    </>
                  )}
                </Box>
                <Grid templateColumns="repeat(5, 1fr)" gap={6} pt={5}>
                  {files?.map((file, index) => (
                    <GridItem w={'100%'} key={file.id} position="relative">
                      <Image src={file.preview} w="full" h="full" objectFit="contain" />
                      <IconButton bg="transparent" position="absolute" top="0" right="0" onClick={() => onRemove(index)}>
                        <BsXCircle size={16} color="red" />
                      </IconButton>
                    </GridItem>
                  ))}
                </Grid>
                {!!error.thumbnailFile && (
                  <Text pt={1} color={'red.500'} fontSize="13px">
                    {error.thumbnailFile}
                  </Text>
                )}
              </FormControl>
              <FormControl isRequired pt={4}>
                <FormLabel minW="150px">Icon</FormLabel>
                <Box
                  {...getRootPropsIcon()}
                  cursor="pointer"
                  borderWidth="1px"
                  borderStyle="dashed"
                  borderRadius="12px"
                  padding="55px"
                  textAlign="center"
                  height="160px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <input {...getInputPropsIcon()} />
                  <BsUpload size={40} />
                  {isDragEnter ? (
                    <p>Thả icon của bạn</p>
                  ) : (
                    <>
                      <p>
                        <Text as="span" color="red.400">
                          Tải tập tin lên
                        </Text>
                        &nbsp;hoặc kéo và thả
                      </p>
                    </>
                  )}
                </Box>
                <Grid templateColumns="repeat(5, 1fr)" gap={6} pt={5}>
                  {icons?.map((file, index) => (
                    <GridItem w={'100%'} key={file.id} position="relative">
                      <Image src={file.preview} w="full" h="full" objectFit="contain" />
                      <IconButton bg="transparent" position="absolute" top="0" right="0" onClick={() => onRemove(index)}>
                        <BsXCircle size={16} color="red" />
                      </IconButton>
                    </GridItem>
                  ))}
                </Grid>
                {!!error.icon && (
                  <Text pt={1} color={'red.500'} fontSize="13px">
                    {error.icon}
                  </Text>
                )}
              </FormControl>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => {
                  onClose(ModalType.ChangeStatus);
                }}
              >
                Hủy
              </Button>
              <Button
                colorScheme="blue"
                ml={3}
                isLoading={createSiteMutation.isPending || updateSiteMutation.isPending}
                onClick={handleSubmit(onSubmit)}
              >
                {categorysDetail ? 'Cập nhập' : 'Tạo'}
              </Button>
            </AlertDialogFooter>
          </form>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default UpdateSiteModal;
