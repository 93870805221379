import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useQueryGetListCategory, useQueryGetListSite } from 'services/category';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { ModalType, countries, countriesSlug } from 'constants/common';
import SizeTable from './components/Table';
import Pagination from 'components/Pagination/Pagination';
import UpdateSiteModal from './components/UpdateSiteModal';
import { Select } from 'chakra-react-select';
import { createArray, mappingOptionSelect } from 'utils/mapping';

function Site() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [sizeEditing, setSizeEditing] = useState(null);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const [search, setSearch] = useState({
    countrySlug: '',
    categoryId: '',
  });
  const { data: categories } = useQueryGetListCategory({
    pageIndex: 0,
    pageSize: 50,
  });

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { isOpen: isChangeStatusModalOpen, onOpen: onOpenChangeStatusModal, onClose: onCloseChangeStatusModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.ChangeStatus]: onOpenChangeStatusModal,
    }),
    [onOpenChangeStatusModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.ChangeStatus]: onCloseChangeStatusModal,
    }),
    [onCloseChangeStatusModal]
  );

  const { data: sites, refetch } = useQueryGetListSite({ ...filter }, { enabled: isLoggedIn });

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setSizeEditing(size);
  };

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setSizeEditing(null);
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      ...search,
    });
  };
  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'}>
              <Flex direction="column" gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Quản lý site
                </Text>
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
                <Stack>
                  <Flex alignItems={'center'} gap={'20px'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                    {/* <FormControl minWidth={{ base: 'full', sm: '300px' }}>
                      <FormLabel>Tìm kiếm site</FormLabel>
                      <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                    </FormControl> */}
                    <FormControl minWidth={{ base: 'full', sm: '200px' }}>
                      <FormLabel>Quốc gia</FormLabel>
                      <Select
                        isClearable
                        menuShouldBlockScroll
                        onChange={e => {
                          setSearch({ ...search, countrySlug: e?.value });
                        }}
                        options={createArray(countries, countriesSlug)}
                      ></Select>
                    </FormControl>
                    <FormControl minWidth={{ base: 'full', sm: '200px' }}>
                      <FormLabel>Danh mục</FormLabel>
                      <Select
                        isClearable
                        menuShouldBlockScroll
                        onChange={e => {
                          setSearch({ ...search, categoryId: e?.value });
                        }}
                        options={mappingOptionSelect(categories?.data, 'title','_id')}
                      ></Select>
                    </FormControl>
                    <Button px={10} variant="primary" maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Tìm kiếm
                      </Text>
                    </Button>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
            <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} onClick={onOpenChangeStatusModal}>
              <Text fontSize="md" fontWeight="bold" cursor="pointer">
                Thêm
              </Text>
            </Button>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <SizeTable categorysData={sites?.data || []} handleUpdateCategory={handleUpdateItem} refetch={refetch} />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={sites?.pagination?.page}
              pageLength={sites?.pagination?.pageSize}
              totalRecords={sites?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
      {isChangeStatusModalOpen && (
        <UpdateSiteModal categories={categories?.data} categorysDetail={sizeEditing} isOpen={isChangeStatusModalOpen} onClose={handelCloseModal} refetch={refetch} />
      )}
    </Flex>
  );
}

export default Site;
