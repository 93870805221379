import { useMutation, useQuery } from '@tanstack/react-query';
import { ROOT_API } from 'constants/common';
import { request } from 'utils/request';

export const getListCategory = (params) => request.get(ROOT_API + '/api/v1/categories', { params });
export const getListSite = (params) => request.get(ROOT_API + '/api/v1/sites', { params });
export const getHistoryMatchReviewById = (reviewId, params) => request.get(ROOT_API + `/api/v1/review/history-match-review/${reviewId}`, { params });
export const createCategory = data => request.post(ROOT_API + '/api/v1/categories/create', data);
export const updateCategory = data => request.post(ROOT_API + '/api/v1/categories/update', data);
export const deleteCategory = data => request.post(ROOT_API + '/api/v1/categories/delete', data);
export const createSite = data => request.post(ROOT_API + '/api/v1/sites/create', data);
export const updateSite = data => request.post(ROOT_API + '/api/v1/sites/update', data);
export const deleteSite = data => request.post(ROOT_API + '/api/v1/sites/delete', data);

export const updateThumnail = async (data) => {
  return await request.post(ROOT_API + '/api/v1/common/upload-thumbnail', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

// Mutation
export const useUpdateThumnailMutation = () => useMutation({ mutationFn: updateThumnail });
export const useCreateCategoryMutation = () => useMutation({ mutationFn: createCategory });
export const useUpdateCategoryMutation = () => useMutation({ mutationFn: updateCategory });
export const useDeleteCategoryMutation = () => useMutation({ mutationFn: deleteCategory });
export const useCreateSiteMutation = () => useMutation({ mutationFn: createSite });
export const useUpdateSiteMutation = () => useMutation({ mutationFn: updateSite });
export const useDeleteSiteMutation = () => useMutation({ mutationFn: deleteSite });

// Query
export const useQueryGetListCategory = (params = {}, options = {}) => useQuery({ queryKey: ['getListCategory', params], queryFn: () => getListCategory(params), ...options });
export const useQueryGetListSite = (params = {}, options = {}) => useQuery({ queryKey: ['getListSite', params], queryFn: () => getListSite(params), ...options });
export const useQueryGetHistoryMatchReviewById = (reviewId, params = {}, options = {}) => useQuery({ queryKey: ['getHistoryMatchReviewById', params], queryFn: () => getHistoryMatchReviewById(reviewId, params), ...options });
