import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Text,
  FormControl,
  FormLabel,
  Box,
  Grid,
  GridItem,
  Image,
  IconButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import InputController from 'components/Form/InputController';
import { ModalType, ROOT_API } from 'constants/common';
import { toast } from 'components/Toast';
import { CategoryFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { BsUpload, BsXCircle } from 'react-icons/bs';
import { updateThumnail, useCreateCategoryMutation, useUpdateCategoryMutation } from 'services/category';
import { useDropzone } from 'react-dropzone';
import { nanoid } from 'nanoid';
import { isEmpty } from 'lodash';

const UpdateCategoryModal = ({ isOpen, categorysDetail, onClose, refetch }) => {
  const [error, setError] = useState({
    thumbnailFile: null,
  });
  const [files, setFiles] = useState([]);
  const [isDragEnter, setIsDragEnter] = useState(false);
  const cancelRef = React.useRef();
  const createCategoryMutation = useCreateCategoryMutation();
  const updateCategoryMutation = useUpdateCategoryMutation();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(CategoryFormValidate),
    defaultValues: {
      title: '',
      description: '',
    },
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDragEnter: () => {
      setIsDragEnter(true);
    },
    onDragLeave: () => {
      setIsDragEnter(false);
    },
    onDrop: acceptedFiles => {
      if(acceptedFiles.length > 1) {
        setError({ thumbnailFile: 'Chỉ upload tối đa 1 hình.' });
        return;
      }
      setError({ thumbnailFile: null });
      setIsDragEnter(false);
      setFiles([
        ...acceptedFiles.map(file => ({
          id: nanoid(),
          preview: URL.createObjectURL(file),
          file,
        })),
      ]);
    },
  });

  const onUploadPhoto = async () => {
    const formData = new FormData();
    const filesUpload = files.filter(item => item.file);
    const filesExist = files.filter(item => !item.file);
    let pathFiles = '';

    if (!isEmpty(filesUpload)) {
      filesUpload.map(item => {
        formData.append('thumbnailFile', item.file);
      });
      const response = await updateThumnail(formData);
      pathFiles = response?.data;
    }
    return [...filesExist, pathFiles];
  };

  useEffect(() => {
    if (categorysDetail) {
      reset({
        title: categorysDetail.title,
        description: categorysDetail.description,
        thumbnail: categorysDetail.thumbnail,
        id: categorysDetail._id
      });
      categorysDetail?.thumbnail &&
        setFiles([
          {
            id: nanoid(),
            preview: ROOT_API + '/' + categorysDetail.thumbnail,
            path: categorysDetail.thumbnail,
          },
        ]);
    }
  }, [categorysDetail]);


  const onSubmit = async dataForm => {
    if (isEmpty(files)) {
      setError({ thumbnailFile: 'Tải lên 1 hình ảnh' });
      return;
    }
    const photosPath = await onUploadPhoto();
    const dataSubmit = {
      ...dataForm,
      thumbnail: photosPath[0]?.path ?? photosPath[0],
    };
    
    if (!!categorysDetail) {
      updateCategoryMutation.mutate(
        { ...dataSubmit },
        {
          onSuccess: () => {
            refetch();
            toast.showMessageSuccess('Cập nhập danh mục thành công');
            onClose(ModalType.ChangeStatus);
          },
          onError: () => {
            toast.showMessageError('Cập nhập danh mục thất bại');
          },
        }
      );

      return;
    }

    createCategoryMutation.mutate(dataSubmit, {
      onSuccess: () => {
        refetch();
        toast.showMessageSuccess('Tạo danh mục thành công');
        onClose(ModalType.ChangeStatus);
      },
      onError: () => {
        toast.showMessageError('Tạo danh mục thất bại');
      },
    });
  };

  const onRemove = index => {
    setFiles(prev => prev.filter((i, idX) => idX !== index));
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.ChangeStatus);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">{categorysDetail ? 'Cập nhật' : 'Tạo'} Danh Mục</AlertDialogHeader>
          <AlertDialogCloseButton />
            <form>
          <AlertDialogBody>
            <InputController
              isRequired
              styleContainer={{
                marginBottom: '10px',
              }}
              control={control}
              name="title"
              label="Tên"
            />
            <InputController
              styleContainer={{
                marginBottom: '10px',
              }}
              control={control}
              isRequired
              name="description"
              label="Mô tả"
              type="textarea"
            />
            <FormControl isRequired pt={4}>
              <FormLabel minW="150px">Ảnh</FormLabel>
              <Box
                {...getRootProps()}
                cursor="pointer"
                borderWidth="1px"
                borderStyle="dashed"
                borderRadius="12px"
                padding="55px"
                textAlign="center"
                height="210px"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <input {...getInputProps()} />
                <BsUpload size={40} />
                {isDragEnter ? (
                  <p>Thả ảnh của bạn</p>
                ) : (
                  <>
                    <p>
                      <Text as="span" color="red.400">
                        Tải tập tin lên
                      </Text>
                      &nbsp;hoặc kéo và thả
                    </p>
                  </>
                )}
              </Box>
              <Grid templateColumns="repeat(5, 1fr)" gap={6} pt={5}>
                {files?.map((file, index) => (
                  <GridItem w={'100%'} key={file.id} position="relative">
                    <Image src={file.preview} w="full" h="full" objectFit="contain" />
                    <IconButton bg="transparent" position="absolute" top="0" right="0" onClick={() => onRemove(index)}>
                      <BsXCircle size={16} color="red" />
                    </IconButton>
                  </GridItem>
                ))}
              </Grid>
              {!!error.thumbnailFile && (
                <Text pt={1} color={'red.500'} fontSize="13px">
                  {error.thumbnailFile}
                </Text>
              )}
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.ChangeStatus);
              }}
            >
              Hủy
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              isLoading={createCategoryMutation.isPending || updateCategoryMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              {categorysDetail ? 'Cập nhập' : 'Tạo'}
            </Button>
          </AlertDialogFooter>
            </form>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default UpdateCategoryModal;
