export const ROOT_API = process.env.REACT_APP_API_HOST;
export const TINY_API_KEY = process.env.REACT_APP_TINY_API_KEY;
export const ROOT_APP = process.env.REACT_APP_HOST;
export const ADVERTISER_PAGE_URL = process.env.REACT_APP_ADVERTISER_URL;

export const MAX_PHONE_NUMBER_DIGIT = 10;
export const defaultPassword = 'CrmEmail2023@';
export const MAX_VIDEO_UPLOAD = 1024 * 1024 * 30;
export const Roles = {
  ADMIN: 'admin',
  GUEST: 'guest',
  USER: 'user',
  MANAGER: 'manager',
};

export const ToastStatus = {
  Success: 'success',
  Error: 'error',
  Warning: 'warning',
  Info: 'info',
};

export const EMAIL_REGEX = /^([a-zA-Z0-9])+(([a-zA-Z0-9_.-])*)+([a-zA-Z0-9])@(([a-zA-Z0-9-])+([a-zA-Z0-9])+\.)([a-zA-Z]{2,})((\.+([a-zA-Z]{2,}))*)$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/;
export const PHONE_REGEX = /^(0|84|\+84|084)\d{9}$/;
export const LINK_REGEX = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
export const KEY_REGEX = /_/g;
export const CONVERT_UPPERCASE_REGEX = /\b\w/g;
export const URL_REGEX = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const ContactChannelKey = {
  Facebook: 'facebook',
  Telegram: 'telegram',
  Skype: 'skype',
};

export const ContactChannelOptions = Object.keys(ContactChannelKey).map(key => ({
  label: key,
  value: ContactChannelKey[key],
}));

export const AVAILABLE_AD_UNITS = {
  POP_UNDER: 'POP_UNDER',
  BANNER_300_250: 'BANNER_300_250',
  BANNER_320_50: 'BANNER_320_50',
  BANNER_300_600: 'BANNER_300_600',
  BANNER_160_600: 'BANNER_160_600',
  BANNER_970_90: 'BANNER_970_90',
  BANNER_640_320: 'BANNER_640_320',
  BANNER_320_100: 'BANNER_320_100',
  NATIVE_BANNER: 'NATIVE_BANNER',
  DIRECT_LINK: 'DIRECT_LINK',
  SOCIAL_BAR: 'SOCIAL_BAR',
};

const BANNER_SIZE = {
  BANNER_300_250: '300x250',
  BANNER_320_50: '320x50',
  BANNER_300_600: '300x600',
  BANNER_160_600: '160x600',
  BANNER_970_90: '970x90',
  BANNER_640_320: '640x320',
  BANNER_320_100: '320x100',
};

const FONT_SIZE = {
  inherit: 'inherit',
  9: '9px',
  10: '10px',
  11: '11px',
  12: '12px',
  13: '13px',
  14: '14px',
  15: '15px',
  16: '16px',
  17: '17px',
  18: '18px',
  19: '19px',
  20: '20px',
};

const WIDGET_LAYOUT = {
  '1:1': '1:1',
  '1:2': '1:2',
  '1:3': '1:3',
  '1:4': '1:4',
  '2:1': '2:1',
  '2:2': '2:2',
  '3:1': '3:1',
  '4:1': '4:1',
};

export const BannerSizeOptions = Object.keys(BANNER_SIZE).map(key => ({
  label: key,
  value: BANNER_SIZE[key],
}));

export const FontSizeOptions = Object.keys(FONT_SIZE).map(key => ({
  label: FONT_SIZE[key],
  value: FONT_SIZE[key],
}));

export const WidgetLayoutOptions = Object.keys(WIDGET_LAYOUT).map(key => ({
  label: key,
  value: WIDGET_LAYOUT[key],
}));

export const AdUnitsOptions = Object.keys(AVAILABLE_AD_UNITS).map(key => ({
  label: key,
  value: AVAILABLE_AD_UNITS[key],
}));

const DIRECT_LINK_CATEGORY = {
  ADULT: 'Adult',
  NON_ADULT: 'Non-adult',
};

export const DirectLinkCategoryOptions = Object.keys(DIRECT_LINK_CATEGORY).map(key => ({
  label: DIRECT_LINK_CATEGORY[key],
  value: key,
}));

const REMOVE_WEBSITE_WITH = {
  EROTIC_ADS: 'EROTIC_ADS',
  SOFTWARE_ADS: 'SOFTWARE_ADS',
  ALERT_ADS: 'ALERT_ADS',
  ADS_WITH_SOUND: 'ADS_WITH_SOUND',
  GAMBLING_ADS: 'GAMBLING_ADS',
};

export const convertEnumToLabelOption = target => {
  return target
    .replace(KEY_REGEX, ' ')
    .toLowerCase()
    .replace(CONVERT_UPPERCASE_REGEX, l => l.toUpperCase());
};

export const RemoveWebsiteOptions = Object.keys(REMOVE_WEBSITE_WITH).map(key => ({
  label: convertEnumToLabelOption(key),
  value: REMOVE_WEBSITE_WITH[key],
}));

export const CountryKey = {
  Vietnam: 'VN',
  Thailan: 'TH',
  Other: 'other',
};

export const CountryOptions = [
  {
    label: 'Việt Nam',
    value: CountryKey.Vietnam,
  },
  {
    label: 'Thái Lan',
    value: CountryKey.Thailan,
  },
  {
    label: 'Khác',
    value: CountryKey.Other,
  },
];

export const TYPE_TICKET = {
  GENERAL_QUESTION: 'GENERAL_QUESTION',
  MY_ACCOUNT: 'MY_ACCOUNT',
  PAYMENTS: 'PAYMENTS',
  REQUEST_MANAGER: 'REQUEST_MANAGER',
  VISA_MASTERCARD: 'VISA_MASTERCARD',
};

export const TypeTicket = Object.keys(TYPE_TICKET).map(key => ({
  label: convertEnumToLabelOption(key),
  value: TYPE_TICKET[key],
}));

export const TYPE_ACTION = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  DETAIL: 'DETAIL',
};

export const TabFeatureProfile = {
  IdentityInfo: 'Identity Info',
  ChangePassword: 'Change Password',
};

export const IS_VERIFIED_INFO = {
  PENDING: 'PENDING',
  WAITING: 'WAITING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const DIRECTION = {
  VERTICAL: 'column',
  HORIZONTAL: 'row',
};

export const GROUP_BY_WEBSITE_ADS = {
  DATE: 'DATE',
  PLACEMENT: 'PLACEMENT',
  COUNTRY: 'COUNTRY',
  DEVICE: 'DEVICE',
  DOMAIN: 'DOMAIN',
  SYSTEM: 'SYSTEM',
};

export const GroupByOptions = Object.keys(GROUP_BY_WEBSITE_ADS).map(key => ({
  label: key,
  value: GROUP_BY_WEBSITE_ADS[key],
}));

export const ModalType = {
  Add: 'add',
  Delete: 'delete',
  ChangeStatus: 'changeStatus',
  EmailBackup: 'emailBackup',
  Import: 'import',
  Preview: 'preview',
  Detail: 'detail',
  ResetPassword: 'resetPassword',
  Assign: 'assign',
  Block: 'block',
  SetPosition: 'setPosition',
};

export const FileImageValid = ['png', 'jpeg', 'jpg'];

export const TypeCategory = [
  {
    label: 'MAIN COURSE',
    value: 'MAIN_COURSE',
  },
  {
    label: 'SUB COURSE',
    value: 'SUB_COURSE',
  },
];

export const POSITION_MENU = {
  CAROUSEL_TWO_ROW: 'CAROUSEL_TWO_ROW',
  CAROUSEL_ONE_ROW: 'CAROUSEL_ONE_ROW',
  LIST_FIRST: 'LIST_FIRST',
  LIST_SECOND: 'LIST_SECOND',
  LIST_THIRD: 'LIST_THIRD',
  LIST_FOURTH: 'LIST_FOURTH',
  LIST_FIFTH: 'LIST_FIFTH',
  LIST_NO_IMAGE: 'LIST_NO_IMAGE',
};

export const PositionCategoryOptions = Object.keys(POSITION_MENU).map(key => ({
  label: POSITION_MENU[key].replace(/_/g, ' '),
  value: POSITION_MENU[key],
}));

export const CartStatus = {
  PROCESSING: "PROCESSING",
  SUBMITTED: "SUBMITTED",
  REJECTED: "REJECTED",
  ACCEPTED: "ACCEPTED",
}

export const STATUS_BLOCK = {
  NORMAL: "Normal",
  TEMPORARY: "Temporary",
  PERMANENTLY: "Permanently",
}

export const StatusBlockOptions = Object.keys(STATUS_BLOCK).map(key => ({
  label: STATUS_BLOCK[key],
  value: STATUS_BLOCK[key],
}));

export const countries = {
  '1': 'Hoa Kỳ',
  '2': 'Canada',
  '4': 'Albania',
  '5': 'Algeria',
  '12': 'Argentina',
  '13': 'Armenia',
  '15': 'Úc',
  '16': 'Áo',
  '17': 'Azerbaijan',
  '20': 'Bangladesh',
  '22': 'Belarus',
  '23': 'Bỉ',
  '28': 'Bolivia',
  '32': 'Brazil',
  '34': 'Brunei',
  '35': 'Bulgari',
  '38': 'Campuchia',
  '39': 'Cameroon',
  '44': 'Chile',
  '45': 'Trung Quốc',
  '48': 'Colombia',
  '50': 'Congo',
  '52': 'Costa Rica',
  '53': 'Croatia',
  '54': 'Cuba',
  '55': 'Síp',
  '56': 'Cộng Hoà Séc',
  '57': 'Đan Mạch',
  '61': 'Đông Timo',
  '62': 'Ecuador',
  '63': 'Ai Cập',
  '72': 'Phần Lan',
  '73': 'Pháp',
  '81': 'Đức',
  '82': 'Ghana',
  '84': 'Hy Lạp',
  '95': 'Honduras',
  '96': 'Hồng Kông',
  '97': 'Hungary',
  '98': 'Iceland',
  '99': 'Ấn Độ',
  '100': 'Indonesia',
  '101': 'Iran',
  '102': 'Iraq',
  '103': 'Ireland',
  '104': 'Israel',
  '105': 'Ý',
  '107': 'Jamaica',
  '108': 'Nhật Bản',
  '113': 'Triều Tiên',
  '114': 'Hàn Quốc',
  '118': 'Lào',
  '119': 'Latvia',
  '122': 'Liberia',
  '127': 'Macao',
  '128': 'Macedonia',
  '131': 'Malaysia',
  '140': 'Mexico',
  '144': 'Mông Cổ',
  '147': 'Ma Rốc',
  '149': 'Myanmar',
  '153': 'Hà Lan',
  '156': 'New Zealand',
  '159': 'Nigeria',
  '163': 'Na Uy',
  '164': 'Oman',
  '165': 'Pakistan',
  '169': 'Paraguay',
  '170': 'Peru',
  '171': 'Philippines',
  '173': 'Ba Lan',
  '174': 'Bồ Đào Nha',
  '176': 'Qatar',
  '178': 'Romania',
  '179': 'Nga',
  '187': 'Ả Rập Xê-Út',
  '188': 'Senegal',
  '189': 'Serbia',
  '192': 'Singapore',
  '193': 'Slovakia',
  '194': 'Slovenia',
  '197': 'Nam Phi',
  '199': 'Tây Ban Nha',
  '200': 'Sri Lanka',
  '207': 'Thuỵ Điển',
  '208': 'Thuỵ Sĩ',
  '210': 'Đài Loan',
  '213': 'Thái Lan',
  '214': 'Togo',
  '218': 'Tunisia',
  '219': 'Thổ Nhỉ Kỳ',
  '224': 'Ukraina',
  '226': 'Anh',
  '228': 'Uruguay',
  '229': 'Uzbekistan',
  '232': 'Venezuela',
  '233': 'Việt Nam',
};

export const countriesSlug = {
  '1': 'hoa-ky',
  '2': 'canada',
  '4': 'albania',
  '5': 'algeria',
  '12': 'argentina',
  '13': 'armenia',
  '15': 'uc',
  '16': 'ao',
  '20': 'bangladesh',
  '22': 'belarus',
  '23': 'bi',
  '28': 'bolivia',
  '32': 'brazil',
  '34': 'brunei',
  '35': 'bulgari',
  '38': 'campuchia',
  '39': 'cameroon',
  '44': 'chile',
  '45': 'trung-quoc',
  '48': 'colombia',
  '50': 'congo',
  '52': 'costa-rica',
  '53': 'croatia',
  '54': 'cuba',
  '55': 'sip',
  '56': 'cong-hoa-sec',
  '57': 'dan-mach',
  '61': 'dong-timo',
  '62': 'ecuador',
  '63': 'ai-cap',
  '72': 'phan-lan',
  '73': 'phap',
  '81': 'duc',
  '82': 'ghana',
  '84': 'hy-lap',
  '95': 'honduras',
  '96': 'hong-kong',
  '97': 'hungary',
  '98': 'iceland',
  '99': 'an-do',
  '100': 'indonesia',
  '101': 'iran',
  '102': 'iraq',
  '103': 'ireland',
  '104': 'israel',
  '105': 'y',
  '107': 'jamaica',
  '108': 'nhat-ban',
  '113': 'trieu-tien',
  '114': 'han-quoc',
  '118': 'lao',
  '119': 'latvia',
  '122': 'liberia',
  '127': 'macao',
  '128': 'macedonia',
  '131': 'malaysia',
  '140': 'mexico',
  '144': 'mong-co',
  '147': 'ma-roc',
  '149': 'myanmar',
  '153': 'ha-lan',
  '156': 'new-zealand',
  '159': 'nigeria',
  '163': 'na-uy',
  '164': 'oman',
  '165': 'pakistan',
  '169': 'paraguay',
  '170': 'peru',
  '171': 'philippines',
  '173': 'ba-lan',
  '174': 'bo-dao-nha',
  '176': 'qatar',
  '178': 'romania',
  '179': 'nga',
  '187': 'a-rap-xe-ut',
  '188': 'senegal',
  '189': 'serbia',
  '192': 'singapore',
  '193': 'slovakia',
  '194': 'slovenia',
  '197': 'nam-phi',
  '199': 'tay-ban-nha',
  '200': 'sri-lanka',
  '207': 'thuy-dien',
  '208': 'thuy-si',
  '210': 'dai-loan',
  '213': 'thai-lan',
  '214': 'togo',
  '218': 'tunisia',
  '219': 'tho-nhi-ky',
  '224': 'ukraina',
  '226': 'anh',
  '228': 'uruguay',
  '229': 'uzbekistan',
  '232': 'venezuela',
  '233': 'viet-nam',
};

